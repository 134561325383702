@import './libs/bootstrap.css';
/* import local files from your web folder */
/* @import './libs/lightgallery.min.css'; */

/* import files from npm packages (node_modules folder has to be marked as Resource Root) */
@import 'slick-carousel/slick/slick.css';
@import 'video.js/dist/video-js.min.css';
@import 'lightgallery/dist/css/lightgallery.css';
@import 'izitoast/dist/css/iziToast.css';
@import '@elements/datepicker/datepicker.css';
@import '@elements/cookie-permissions/cookie.css';
@import '@elements/elements-job-bundle';

@import "@elements/alert-notification/alert-notification.css";
@import '@elements/floating-labels/floating-label.css';
@import 'jquery-ui/dist/themes/base/jquery-ui.css';

@import './style/variables.pcss';

.cookie-bar{
 z-index: 1000000000;
}

.form-control.has-value~.floating-label, .form-control:focus~.floating-label, select~.floating-label {
 transform: translateY(-66%) scale(.55);
}
.form-control.js-floating-label{
 padding-top: 25px;
 height: calc(2.25rem + 15px);
}


.datepicker .form-control:read-only{
 background: #fff;
}

#ui-datepicker-div{
 transform: translateY(-140px);
 @media screen and (max-width: 767px) {
  transform: translateY(-75px);
 }
}

.ui-datepicker .ui-datepicker-prev .icon{
 position: static;
 margin-top: 0;
 margin-left: 0;
 text-indent: 0;
 transform: rotate(180deg);
 transform-origin: 50% 45%;
}
.ui-datepicker .ui-datepicker-next .icon{
 position: static;
 margin-top: 0;
 margin-left: 0;
 text-indent: 0;
}

.ui-datepicker .ui-datepicker-next-hover, .ui-datepicker .ui-datepicker-prev-hover {
 top: 2px;
}
.ui-datepicker .ui-datepicker-next{
 right: 1px;
}
.ui-datepicker .ui-datepicker-prev-hover{
 left: 2px;
}

.page-item.disabled .page-link{
 background: transparent;
}

